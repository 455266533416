/* eslint-disable no-console */
import Vue from 'vue'
import router from '@/app/router'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { isEqual, chain, omit } from 'lodash'

import {
  formatDate,
  getTimestampForError,
  getTranslatedHotelContacts,
  getInternalCodeFromResponse,
  getErrorMessageWithTimestamp,
  isLocaleSupported,
  parseLocale,
  showAlertPopup
} from '../system/helper'
import httpClient from '../system/httpClient'
import { createBookingRequest } from './modules/bookingRequest'
import { localStorageHandler } from '../system/mixins/localStorage'
import {
  captureRequestException,
  captureExceptionWithScope
} from '../system/errorHandling'
import { browserLang, fallbackLang, i18n } from '../vue-config/i18n'
import { ERROR_CODES, JSON_STUB } from '../constants'
import { ReservationStatus } from '../system/constants/reservation'

const headers = {
  contentType: JSON_STUB.content,
  'JsonStub-User-Key': JSON_STUB.userKey,
  'JsonStub-Project-Key': JSON_STUB.projectKey
}

export default {
  setCancellationLoading({ commit }, data) {
    commit('SET_CANCELLATION_LOADING', data)
  },
  setLoading({ commit }, data) {
    commit('SET_LOADING', data)
  },
  setLanguage({ commit }, data) {
    commit('SET_LANGUAGE', data)
  },
  setLoggedStatus({ commit }, data) {
    commit('SET_LOGGED_STATUS', data)
  },
  async setConfig(store) {
    await httpClient.get('config').then(
      (response) => {
        response.body && store.commit('SET_CONFIG', response.body)
      },
      (response) => {
        // eslint-disable-next-line quotes
        showAlertPopup(
          i18n.t("App#Error Can't load hotel data") +
            ` (${Vue.prototype.$hotelId})`
        )
        captureRequestException('Config request has been failed', response)
      }
    )
  },
  async getPrivacyPolicy({ commit }) {
    try {
      const response = await httpClient.get('privacyPolicy')
      if (response.status === 200) {
        const policy = response.bodyText
        commit('SET_PRIVACY_POLICY', policy)
        return policy
      }
    } catch (error) {
      captureRequestException('Privacy policy request has been failed', error)
      try {
        const response = await httpClient.get('privacyPolicyDefault')
        if (response.status === 200) {
          const policy = response.bodyText
          commit('SET_PRIVACY_POLICY', policy)
          return policy
        }
      } catch (error) {
        captureRequestException(
          'Default privacy policy request has been failed',
          error
        )
        return null
      }
    }
  },
  async getDescriptiveInfo(store) {
    store.commit('SET_LOADING', true)
    return await httpClient.get('descriptiveInfo').then(
      (response) => {
        if (response.status == 200) {
          store.commit('SET_DESCRIPTIVE_INFO', response.data)
          if (location.search.indexOf('currency') === -1) {
            // check if there's any currency indication in the URL
            let sessionCurrency = Vue.ls.get('currency')
            if (!sessionCurrency) {
              store.commit('SET_CURRENCY', response.data.currencyCode) // set currency to the hotel's one by default
            }
          }
          store.commit('SET_DESCRIPTIVE_INFO_LOADED', true)
          store.dispatch('getProductsAndPackages')
        }

        return Promise.resolve()
      },
      (response) => {
        captureRequestException(
          'Descriptive info request has been failed',
          response
        )
      }
    )
  },
  async getPromotionInfo(store) {
    store.commit('SET_LOADING', true)
    return await httpClient.get('promotionsInfo').then(
      async (response) => {
        if (response.status == 200) {
          store.commit('SET_PROMOTIONS_INFO', response.data)
        }
        return Promise.resolve()
      },
      (response) => {
        captureRequestException(
          'Promotions info request has been failed',
          response
        )
      }
    )
  },
  getProducts(store) {
    return new Promise((resolve, reject) => {
      httpClient.get('products').then(
        (response) => {
          if (response.status == 200 && response.data) {
            store.commit('SET_PRODUCTS', response.data)
          }
          resolve()
        },
        (response) => {
          captureRequestException('Product request has been failed', response)
          reject()
        }
      )
    })
  },
  async getHotelPackages({ state, commit }) {
    try {
      state.hotelPackagesLoaded = false

      // Make API call only if test is true or subscription is not 'light'
      if (state.test || state.subscription !== 'light') {
        const response = await httpClient.get('hotelPackages')
        if (response.status === 200) {
          commit('SET_HOTEL_PACKAGES', response.data)
        }
      }
    } catch (error) {
      captureRequestException('Package request failed', error)
    } finally {
      state.hotelPackagesLoaded = true
    }
  },
  async getProductsAndPackages({ dispatch }) {
    await dispatch('getProducts')
    await dispatch('getHotelPackages')

    if (router.currentRoute.name !== 'package') {
      dispatch('getRoomsAvailabilities')
    }
  },
  async getRoomsAvailabilities({ state, getters, commit, dispatch }, payload) {
    const resetSearchParams = async () => {
      Vue.prototype.$bus.$emit('resetSearchParams')
      await dispatch('replaceRoute', { name: 'home' })
      dispatch('getRoomsAvailabilities')
    }

    commit('SET_LOADING', true)
    commit('SET_AVAILABILITIES_LOADED', false)
    commit('SET_ROOMS', [])

    const { startDate, endDate, nbAdults, nbChildren, nbInfants } =
      getters.currentRoomStay
    const start = formatDate(startDate)
    const end = formatDate(endDate)
    const today = dayjs().startOf('days')

    if (dayjs(start).isBefore(today)) {
      commit('SET_LOADING', false)
      const text = i18n.t(
        'App#Error Your check-in date is in the past. Please, select a valid date'
      )
      showAlertPopup({
        text,
        icon: 'warning'
      }).then(() => {
        Vue.prototype.$bus.$emit('resetSearchParams')
        dispatch('replaceRoute', { name: 'home' })
      })
      return
    }

    const queryParams = {
      sessionId: getters.bookingRequest.sessionId,
      currencyCode: state.currency,
      promoCode: getters.bookingRequest.promoCodeInput?.toUpperCase() || '',
      roomStayIndex: getters.currentRoom + 1,
      start,
      end,
      nbAdults,
      nbChildren,
      nbInfants
    }

    if (payload?.hotelPackageId) {
      queryParams.nbChildren = 0
      queryParams.nbInfants = 0
      queryParams.hotelPackageId = payload.hotelPackageId
    }

    if (!dayjs(start).isValid() || !dayjs(end).isValid()) {
      resetSearchParams()
    }

    return await httpClient.get('availabilities', { queryParams }).then(
      (response) => {
        if (response.status == 200) {
          if (response.data.length) {
            commit('SET_ROOMS', response.data)
          }
          commit('SET_LOADING', false)
          commit('SET_AVAILABILITIES_LOADED', true)

          if (!isEqual(queryParams, state.searchParams)) {
            commit('SET_SEARCH_PARAMS', queryParams)
          }

          return Promise.resolve()
        }
      },
      (response) => {
        const errorMessage = getErrorMessageWithTimestamp({
          sessionId: getters.bookingRequest.sessionId,
          i18nParams: getters.hotelContacts
        })

        showAlertPopup(errorMessage).then(resetSearchParams)

        captureRequestException(
          'Availability request has been failed',
          response
        )
      }
    )
  },
  async getPackagesAvailabilities({ state, getters, commit }, payload) {
    commit('SET_LOADING', true)

    const { startDate, endDate, nbAdults, nbChildren, nbInfants } =
      getters.currentRoomStay
    const start = formatDate(startDate)
    const end = formatDate(endDate)
    const today = dayjs().startOf('days')

    if (dayjs(start).isBefore(today)) {
      commit('SET_LOADING', false)
      const text = i18n.t(
        'App#Error Your check-in date is in the past. Please, select a valid date'
      )
      showAlertPopup({
        text,
        icon: 'warning'
      })
      return
    }

    const queryParams = {
      sessionId: getters.bookingRequest.sessionId,
      hotelPackageId: payload.hotelPackageId,
      currencyCode: state.currency,
      start,
      end,
      nbAdults,
      nbChildren: 0,
      nbInfants: 0
    }

    try {
      const response = await httpClient.get('availabilities', { queryParams })
      if (response.status == 200) {
        commit('SET_HOTEL_PACKAGES_AVAILABLE', response.data)
        commit('SET_LOADING', false)
        return response.data
      }
    } catch (err) {
      const errorMessage = getErrorMessageWithTimestamp({
        sessionId: getters.bookingRequest.sessionId,
        i18nParams: getters.hotelContacts
      })

      showAlertPopup(errorMessage)

      captureRequestException(
        'Packages availability request has been failed',
        err
      )
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async getCancellationPolicies({ dispatch, getters }, params) {
    try {
      const response = await httpClient.get('cancellationPolicies', {
        queryParams: params
      })
      return response.bodyText
    } catch (error) {
      const internalErrorCode = getInternalCodeFromResponse(error)
      if (internalErrorCode === 5601) {
        dispatch(
          'showModalWithHomeRedirect',
          getErrorMessageWithTimestamp({
            sessionId: '',
            internalErrorCode,
            i18nParams: getters.hotelContacts
          })
        )
      }
      captureRequestException(
        'Cancellation policies request has been failed',
        error,
        params
      )
      return {
        error: i18n.t(ERROR_CODES[internalErrorCode]),
        code: internalErrorCode
      }
    }
  },
  async threeDsAuthentication({ getters, dispatch }, body) {
    try {
      let response = await httpClient.post('threeDsAuthentication', { body })
      if (response.ok) {
        const reservation = JSON.parse(response.bodyText)
        return reservation
      }
    } catch (error) {
      const internalErrorCode = getInternalCodeFromResponse(error)
      captureRequestException(
        'threeDsAuthentication request has been failed',
        error,
        body
      )

      if (internalErrorCode === '5908') {
        showAlertPopup({
          title: i18n.t(ERROR_CODES[internalErrorCode]),
          text: i18n.t(
            'Checkout#Payment#Check#Fail Please, try again or change the payment card.'
          ),
          icon: 'error',
          buttons: {
            confirm: i18n.t('Checkout#Payment#Check#Fail Try again')
          }
        }).then(() => {
          router.push({ name: 'home' })
        })
      } else if (internalErrorCode === '5912') {
        dispatch(
          'showModalWithHomeRedirect',
          getErrorMessageWithTimestamp({
            sessionId: '',
            internalErrorCode,
            i18nParams: getters.hotelContacts
          })
        )
      } else {
        dispatch(
          'showModalWithHomeRedirect',
          getErrorMessageWithTimestamp({
            sessionId: '',
            internalErrorCode,
            errorDescription: error.body.error,
            i18nParams: getters.hotelContacts
          })
        )
      }

      return error
    }
  },
  getUpgrades(store, params) {
    const products = store.getters.roomsBeforeRender
    if (products === undefined) {
      store.commit('SET_UPGRADES', [])
    } else {
      let upgrades = products.filter(
        (product) =>
          product.room?.id == params.roomId &&
          !!product.rate &&
          product.rate?.ratePlanCode != params.ratePlanCode &&
          product.totalPrice - product.discount > params.price
      )
      store.commit('SET_UPGRADES', upgrades)
    }
  },
  getUpsell({ getters, commit }, params) {
    const products = getters.roomsBeforeRender
    if (products === undefined) {
      commit('SET_UPSELL', [])
    } else {
      let upsell = products.filter(
        (product) =>
          !!product.rate &&
          product.rate?.ratePlanCode === params.ratePlanCode &&
          product.totalPrice - product.discount > params.price
      )
      commit('SET_UPSELL', upsell)
    }
  },
  getServices(store) {
    store.commit('SET_SERVICES', [])
    store.commit('SET_SERVICES_LOADED', false)
    const {
      bookingRequest,
      currency,
      currentRoomStay: { reference, promotionCode }
    } = store.getters

    const queryParams = {
      sessionId: bookingRequest.sessionId,
      promoCode: promotionCode.toUpperCase(),
      roomStayReference: reference,
      currencyCode: currency
    }

    if (!reference) {
      store.dispatch('replaceRoute', { name: 'home' })
      return
    }

    return new Promise((resolve, reject) => {
      httpClient.get('services', { queryParams }).then(
        (response) => {
          if (response.status == 200) {
            store.commit('SET_SERVICES', response.body)
            store.commit('SET_SERVICES_LOADED', true)
          }
          resolve()
        },
        (response) => {
          captureRequestException('Services request has been failed', response)
          reject()
        }
      )
    })
  },
  getCalendarData(store) {
    let params = {}
    params.hotelId = Vue.prototype.$hotelId
    params.startDate = formatDate(store.state.currentRoomStay.startDate)
    params.endDate = formatDate(store.state.currentRoomStay.endDate)
    params.currencyCode = store.state.currency
    // Vue.http({
    // 	url: `${link}/${calendar}/${params.hotelId}`,
    // 	method: 'GET',
    // 	params: {
    // 		start: params.startDate,
    //         end: params.endDate,
    //         currencyCode: params.currencyCode,
    // 		apiKey: api_Key
    // 	}
    // }).then(response => {
    // 	if (response.status == 200) {
    // 		store.commit('SET_CALENDAR', response.body)
    // 	}
    // }, response => {
    // captureRequestException('Calendar request has been failed', response);
  },
  getBundleData({ commit, getters, dispatch }, { sessionId, bundleId }) {
    return new Promise((resolve, reject) => {
      // update current sessionId
      Vue.cookie.set('sessionId', sessionId, {
        expires: process.env.SESSION_LIFETIME
      })

      httpClient
        .get('bundles', {
          pathParams: {
            sessionId,
            bundleId
          }
        })
        .then(
          (response) => {
            if (response.status == 200) {
              const bundleData = JSON.parse(response.bodyText)
              const {
                arrival: bundleArrival,
                departure: bundleDeparture,
                currency: bundleCurrency
              } = bundleData
              let nbAdultsInBundle = 0,
                nbChildrenInBundle = 0,
                nbInfantsInBundle = 0
              // update currency
              commit('SET_CURRENCY', bundleCurrency)
              // update session params
              /** @var {BookingRequest} */
              const bookingRequest = createBookingRequest(
                JSON.parse(Vue.prototype.$cookie.get('bookingRequest'))
              )
              bookingRequest.sessionId = sessionId
              bookingRequest.checkIn = bundleArrival
              bookingRequest.checkOut = bundleDeparture
              bookingRequest.roomStays = []
              bundleData.roomStays.forEach((rs) => {
                let sellableProduct = getters.productById(rs.sellableProductId)
                bookingRequest.roomStays.push({
                  adults: rs.nbAdults,
                  children: rs.nbChildren,
                  infants: rs.nbInfants || 0,
                  order: {
                    reference: rs.reference,
                    startDate: bundleArrival,
                    endDate: bundleDeparture,
                    currency: bundleCurrency,
                    guestRoomId: rs.guestRoomId,
                    ratePlanId: rs.ratePlanId,
                    nbAdults: rs.nbAdults,
                    nbChildren: rs.nbChildren,
                    nbInfants: rs.nbInfants,
                    product: {
                      nbNights: bookingRequest.nbNights,
                      totalPrice: rs.totalPrice,
                      discount: rs.totalDiscount,
                      totalVat: rs.totalVat,
                      totalLocalTax: rs.totalLocalTax,
                      rate: {
                        ratePlanName: sellableProduct.ratePlans[0].ratePlanName
                      },
                      room: {
                        roomTypeName: sellableProduct.roomTypes[0].roomTypeName
                      }
                    },
                    services: [],
                    totalDiscount: rs.totalDiscount,
                    totalPrice: rs.totalPrice
                  }
                })
                nbAdultsInBundle += rs.nbAdults
                nbChildrenInBundle += rs.nbChildren
                nbInfantsInBundle += rs.nbInfants
              })
              commit('UPDATE_BOOKING_REQUEST', bookingRequest)
              // update "currentRoomStay" info
              commit('UPDATE_CURRENT_ROOM_STAY', {
                startDate: bundleArrival,
                endDate: bundleDeparture,
                nbAdults: nbAdultsInBundle,
                nbChildren: nbChildrenInBundle,
                nbInfants: nbInfantsInBundle
              })

              return resolve(true)
            }
          },
          (response) => {
            captureRequestException('Bundle request has been failed', response)
            reject()
            showAlertPopup({
              text: i18n.t(
                'App#Error This link has expired and is not valid anymore.'
              ),
              icon: 'warning'
            }).then(() => {
              dispatch('replaceRoute', { name: 'home' })
              Vue.prototype.$bus.$emit('refreshSession')
            })
          }
        )
    })
  },
  showModalWithHomeRedirect({ commit, dispatch }, msg, severity = 'error') {
    showAlertPopup(msg, severity).then(() => {
      commit('SET_RESERVATION_LOADING', false)
      dispatch('replaceRoute', { name: 'home' })
      Vue.prototype.$bus.$emit('refreshSession')
    })
  },
  async sendBookingRequest({ state, getters, commit, dispatch }, payload) {
    const { reservation, requestType } = payload
    reservation.language = state.language

    try {
      const response = await httpClient.post(`reservations${requestType}`, {
        queryParams: ReservationStatus.Pay
          ? { token: router.currentRoute.query.t }
          : null,
        body: reservation,
        headers: { 'Content-Type': 'application/json' }
      })

      if (response.ok) {
        const {
          body: { status, reservationId, createDateTime, payment }
        } = response
        if (
          status === ReservationStatus.Book ||
          status === ReservationStatus.Modify ||
          status === ReservationStatus.Pay
        ) {
          commit('UPDATE_ORDER_INFO', {
            reservationId,
            createDateTime,
            payment
          })
          localStorageHandler.lsSave(
            'reservation',
            JSON.stringify({ id: reservationId, ...payment })
          )
        } else {
          captureRequestException(
            `Unable to ${requestType} the reservation`,
            response
          )
          dispatch(
            'showModalWithHomeRedirect',
            getErrorMessageWithTimestamp({
              sessionId: getters.bookingRequest.sessionId,
              internalErrorCode: ERROR_CODES.BOOKING_FAILED,
              i18nParams: getters.hotelContacts
            })
          )
        }
        return Promise.resolve(response)
      }
    } catch (error) {
      const internalErrorCode = getInternalCodeFromResponse(error)
      if (internalErrorCode == 5050) {
        commit('SET_INLINE_BOOKING_REQUEST_ERROR', true)
      } else {
        const errorMessage = getErrorMessageWithTimestamp({
          sessionId: getters.bookingRequest.sessionId,
          internalErrorCode,
          i18nParams: getters.hotelContacts
        })

        dispatch('showModalWithHomeRedirect', errorMessage)
      }
      captureRequestException(`Failed to ${requestType} reservation`, error)

      return Promise.reject(error)
    } finally {
      commit('SET_RESERVATION_LOADING', false)
    }
  },
  sendCancellationRequest({ getters, commit, dispatch }, data) {
    const sessionId = getters.bookingRequest.sessionId
    const reservationId = data.reservationId
    return new Promise((resolve, reject) => {
      httpClient
        .post('reservationsCancel', {
          body: data,
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(
          (response) => {
            if (response.status == 200) {
              if (response.body?.status === ReservationStatus.Cancel) {
                const {
                  body: { bookingChannelId }
                } = response
                commit('SET_CHANNEL_ID', Number(bookingChannelId))
                commit('UPDATE_CANCELLED_ORDER_INFO', response.body)
                commit('SET_ALLOW_CANCELLATION_CONFIRMATION', true)
                commit('SET_CANCELLATION_LOADING', false)
                dispatch('replaceRoute', {
                  name: 'ReservationCancelConfirmation',
                  params: { reservationId }
                })
              } else {
                let errorMsg = i18n.t(
                  'App#Error Cancellation failed. Please check that the reservation ID and email address are correct.'
                )
                errorMsg += `\n\n${i18n.t(
                  getTranslatedHotelContacts(getters.hotelContacts)
                )}`
                errorMsg += getTimestampForError({
                  hotelId: Vue.prototype.$hotelId,
                  sessionId,
                  internalErrorCode: getInternalCodeFromResponse(response)
                })
                showAlertPopup(errorMsg).then(() => {
                  commit('SET_CANCELLATION_LOADING', false)
                })
                captureRequestException(
                  'Cancellation request has been failed',
                  response,
                  { reservationId, sessionId },
                  'cancellation_failed'
                )
              }
            }
          },
          (response) => {
            const internalErrorCode = getInternalCodeFromResponse(response)
            if (internalErrorCode == 5610) {
              reject()
            } else {
              let errorMsg = i18n.t(
                'App#Error Cancellation failed. Please check that the reservation ID and email address are correct.'
              )
              errorMsg += `\n\n${i18n.t(
                getTranslatedHotelContacts(getters.hotelContacts)
              )}`
              errorMsg += getTimestampForError({
                hotelId: Vue.prototype.$hotelId,
                sessionId,
                internalErrorCode: getInternalCodeFromResponse(response)
              })
              showAlertPopup(errorMsg).then(() => {
                commit('SET_CANCELLATION_LOADING', false)
              })
            }
            captureRequestException(
              'Cancellation request has been failed',
              response,
              { sessionId },
              'cancellation_failed'
            )
          }
        )
    })
  },
  async getReservation(
    { getters, commit, dispatch },
    { reservationId, token }
  ) {
    try {
      commit('SET_RESERVATION_LOADING', true)
      const response = await httpClient.get('reservation', {
        pathParams: {
          reservationId
        },
        queryParams: {
          token
        }
      })
      commit('SET_CURRENCY', response.data.currency)
      return response.ok ? response.data : null
    } catch (error) {
      dispatch(
        'showModalWithHomeRedirect',
        getErrorMessageWithTimestamp({
          sessionId: getters.bookingRequest.sessionId,
          internalErrorCode: getInternalCodeFromResponse(error),
          i18nParams: getters.hotelContacts
        })
      )

      captureRequestException(
        'Get reservation data request has been failed',
        error
      )
    } finally {
      commit('SET_RESERVATION_LOADING', false)
    }
  },
  async getReservationTaxes({ getters, commit, dispatch }) {
    const request = getters.bookingRequestToBeSent
    const roomStays = request.roomStays.map((roomStay) =>
      omit(roomStay, ['room', 'products', 'rate', 'taxes'])
    )
    try {
      const response = await httpClient.post('reservationTaxes', {
        body: {
          ...request,
          roomStays
        }
      })
      commit('UPDATE_ROOM_STAYS_TAXES', response.body)
    } catch ({ status, body }) {
      if (status === 400) {
        const internalErrorCode = getInternalCodeFromResponse(body)
        const sessionId = getters.bookingRequest.sessionId
        dispatch(
          'showModalWithHomeRedirect',
          getErrorMessageWithTimestamp({
            sessionId,
            internalErrorCode,
            errorDescription: body.error,
            i18nParams: getters.hotelContacts
          })
        )
        captureRequestException(
          'Tax service request has been failed',
          body,
          { sessionId },
          'tax_service_failed'
        )
      }
    }
  },
  createReservationModifyRequest({ getters, commit }, reservation) {
    const activeRoomStays = reservation.roomStays.filter(
      (roomStay) => roomStay.status != ReservationStatus.Cancel
    )
    const bookingRequest = createBookingRequest({
      checkIn: reservation.startDate,
      checkOut: reservation.endDate,
      guestInfo: reservation.guestInfo,
      roomStays: activeRoomStays,
      promoCodeInput: reservation.promoCode || '',
      paymentInfo: reservation.paymentInfo,
      payableAmount: reservation.payableAmount
    })

    commit('UPDATE_BOOKING_REQUEST', bookingRequest)

    activeRoomStays.forEach((roomStay, roomStayIndex) => {
      const sellableProduct = getters.productMatchingRoomAndRateCode(
        roomStay.guestRoomId,
        roomStay.ratePlanId
      )

      commit('ADD_ORDER_TO_ROOM_STAY', {
        order: {
          currency: roomStay.currencyCode,
          currencyCode: roomStay.currencyCode,
          guestRoomId: roomStay.guestRoomId,
          ratePlanId: roomStay.ratePlanId,
          startDate: formatDate(roomStay.startDate),
          endDate: formatDate(roomStay.endDate),
          datesDiff: roomStay.datesDiff,
          nbAdults: roomStay.nbAdults,
          nbChildren: roomStay.nbChildren || 0,
          nbInfants: roomStay.nbInfants || 0,
          reference: roomStay.reference || '',
          services: roomStay.services || [],
          totalPrice: roomStay.totalPrice || 0,
          totalDiscount: roomStay.totalDiscount || 0,
          product: {
            nbNights: bookingRequest.nbNights,
            totalPrice: roomStay.totalPrice,
            totalVat: roomStay.totalVat || 0,
            totalLocalTax: roomStay.totalLocalTax || 0,
            discount: roomStay.totalDiscount || 0,
            rate: {
              ratePlanName: sellableProduct?.ratePlans[0]?.ratePlanName
            },
            room: {
              roomTypeName: sellableProduct?.roomTypes[0]?.roomTypeName
            }
          }
        },
        roomStayIndex
      })
      /**
       * this commit is needed to request cancellation policies by
       * @method getCancellationPolicies
       */
      commit('UPDATE_CURRENT_ROOM_STAY', {
        ratePlanId: sellableProduct?.ratePlans[0]?.ratePlanCode,
        guestRoomId: sellableProduct?.roomTypes[0]?.roomTypeCode
      })
    })
    return true
  },
  async cancelRoomStay(
    { getters, commit },
    { reservationId, roomStayIndex, token }
  ) {
    try {
      commit('SET_RESERVATION_LOADING', true)
      const response = await httpClient.requestByAction('roomStayCancel', {
        method: 'DELETE',
        pathParams: {
          reservationId,
          roomStayIndex
        },
        queryParams: {
          token
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })

      if (response.ok || response.status == 200) {
        const roomStay = getters.bookingRequest.roomStays.find(
          (rs) => rs.roomStayIndex === roomStayIndex
        )
        commit('REMOVE_ROOM_STAY', roomStay)
        return response
      }
    } catch (error) {
      const internalErrorCode = getInternalCodeFromResponse(error)
      const errorMessage = getErrorMessageWithTimestamp({
        reservationId,
        internalErrorCode,
        errorDescription: getErrorDescription(internalErrorCode),
        errorCodeParams: { roomStayIndex },
        i18nParams: getters.hotelContacts
      })

      Swal.fire({
        icon: 'error',
        title: i18n.t('App#Error Oops!'),
        text: errorMessage
      })

      captureRequestException('Room stay cancel has been failed', error)
    } finally {
      commit('SET_RESERVATION_LOADING', false)
    }
  },
  async getConfigCustomization({ commit }) {
    try {
      commit('SET_LOADING', true)
      const response = await httpClient.get('customization')
      response && commit('UPDATE_CUSTOMIZATION_CONFIG', response.body)
    } catch (error) {
      captureRequestException(
        'Customization data could not be found for this hotel',
        error
      )
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async sendCustomizationData({ state, commit, dispatch }) {
    try {
      commit('SET_LOADING', true)
      const response = await httpClient.requestByAction('customization', {
        body: state.customization,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      response && dispatch('getConfigCustomization')
    } catch (error) {
      captureRequestException(
        'Customization update request has been failed',
        error
      )
    } finally {
      commit('SET_LOADING', false)
    }
  },
  getUser({ commit }, data) {
    let userID = Vue.cookie.get('user'),
      user = null
    Vue.http({
      url: `${oldLink}user`,
      method: 'POST',
      headers: headers
    }).then((response) => {
      if (userID) {
        user = response.data.find((item) => (item.id = userID))
      } else if (data) {
        user = response.data.find(
          (item) => item.email === data.email && item.password === data.password
        )
      } else {
        return false
      }
      if (user) {
        commit('SET_USER', user)
        commit('UPDATE_BOOKING_REQUEST', {
          guestInfo: {
            gender: user.gender || '',
            givenName: user.firstName || '',
            surname: user.lastName || '',
            phone: user.phone || '',
            address: user.address || '',
            email: user.email || ''
          }
        })
        commit('SET_LOGGED_STATUS', true)
        Vue.cookie.set('user', user.id)
        router.push({ name: 'PaymentDetails' })
      }
    })
  },
  logOut({ commit }) {
    commit('SET_LOGGED_STATUS', false)
    commit('UPDATE_BOOKING_REQUEST', {
      guestInfo: {}
    })

    commit('SET_USER', null)
    Vue.cookie.delete('user')
    router.push({ name: 'home' })
  },
  windowResize({ commit }, size) {
    commit('WINDOW_RESIZE', size)
  },
  trackTrivagoConversion(store, body) {
    httpClient.post('trackingTrivago', { body }).catch((response) => {
      captureExceptionWithScope(
        'Trivago conversion request has been failed',
        {
          extraKey: 'RQandRS',
          extraData: { response, requestBody: body },
          tags: { type: 'trivago_request_failed' }
        },
        'fatal'
      )
    })
  },
  trackTrivagoCancellation(store, body) {
    httpClient.del('trackingTrivago', { body }).catch((response) => {
      captureExceptionWithScope(
        'Trivago cancellation request has been failed',
        {
          extraKey: 'RQandRS',
          extraData: { response, requestBody: body },
          tags: { type: 'trivago_request_failed' }
        },
        'fatal'
      )
    })
  },
  trackTrivagoModification(store, body) {
    httpClient.put('trackTrivago', { body }).catch((response) => {
      captureExceptionWithScope(
        'Trivago modification request has been failed',
        {
          extraKey: 'RQandRS',
          extraData: { response, requestBody: body },
          tags: { type: 'trivago_request_failed' }
        },
        'fatal'
      )
    })
  },
  trackTripAdvisorConversion(store, body) {
    httpClient.get('trackingTripAdvisor', { body }).catch((response) => {
      captureExceptionWithScope(
        'TripAdvisor conversion request has been failed',
        {
          extraKey: 'RQandRS',
          extraData: { response, requestBody: body },
          tags: { type: 'tripadvisor_request_failed' }
        },
        'fatal'
      )
    })
  },
  async getAllLanguages() {
    return new Promise((resolve, reject) => {
      httpClient.get('allLanguages').then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  async getRecommendedLanguages() {
    return new Promise((resolve, reject) => {
      httpClient.get('recommendedLanguages').then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  async getLanguages({ dispatch, commit }) {
    try {
      const response = await Promise.all([
        dispatch('getAllLanguages'),
        dispatch('getRecommendedLanguages')
      ])

      const [{ body: langs }, { body: recommended }] = response
      const languages = chain(langs)
        .toPairs()
        .filter((i) => isLocaleSupported(i[0]))
        .sortBy((i) =>
          recommended.indexOf(i[0]) !== -1
            ? recommended.indexOf(i[0])
            : langs.length
        )
        .fromPairs()
        .value()

      commit('SET_LANGUAGES', languages)
      return languages
    } catch (err) {
      captureRequestException('Languages requests have been failed', err)
    }
  },
  async setPreferredLanguage({ state, commit, dispatch }) {
    await dispatch('getLanguages')

    const queryLocale = router.currentRoute.query.lang
    const lsLocale = Vue.ls.get('language')
    const fallbackLocale = browserLang || fallbackLang
    let locale

    if (!lsLocale && !queryLocale) {
      locale = fallbackLocale
    }

    if (queryLocale && isLocaleSupported(queryLocale)) {
      locale = queryLocale
    }

    if (
      queryLocale &&
      !isLocaleSupported(queryLocale) &&
      parseLocale(queryLocale)
    ) {
      locale = parseLocale(queryLocale) || fallbackLocale
    } else if (queryLocale && !parseLocale(queryLocale)) {
      locale = lsLocale || fallbackLocale
    }

    if (!queryLocale && lsLocale) {
      locale = lsLocale
    }

    Vue.ls.set('language', locale)

    if (Object.prototype.hasOwnProperty.call(state.languages, locale)) {
      commit('SET_LANGUAGE', locale)
    }
  },
  async setCurrencies({ commit }) {
    const currencies = await httpClient.securedRequest(
      'currencies',
      'Currencies request has been failed'
    )
    commit('SET_CURRENCIES', currencies)
  },
  async getCurrenciesTranslations({ getters, commit }) {
    const currenciesTranslations = await httpClient.securedRequest(
      'currenciesTranslations',
      'Currencies translations request has been failed'
    )
    i18n.mergeLocaleMessage(getters.language, currenciesTranslations)
  },
  updateSearchInfoDates({ commit }, { start: checkIn, end: checkOut }) {
    commit('UPDATE_BOOKING_REQUEST_DATES', {
      checkIn,
      checkOut
    })
  }
}
